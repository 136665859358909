.element-card{
    width:12rem; 
    min-height: 13rem;
    /* border-radius:0px; 
    border-width:1px; 
    border-style:solid;
    border-color:rgb(131, 131, 131); */
    margin: 1% 1% 1% 1%;
}

.element-card:hover{
    cursor: pointer;
    scale: 1.01;
}

.wide{
    width:24rem;
}

.folder-card{
    width:12rem; 
    margin: 1% 1% 1% 1%;
    align-items: center;

}

.add-card{
    background-color: rgba(181, 242, 162, 0.482);
}

.grey-table-col{
    background-color: rgb(200, 200, 200);
}
.white-table-col{
    background-color: rgba(181, 242, 162, 0.482);
}

.hovered{
    background-color: rgb(127, 197, 130);
    cursor: pointer;
}

.breadcrumb-link{
    color:rgb(66, 66, 235);
}

.breadcrumb-link:hover{
    cursor: pointer;
}

.accordion-body{
    height: 600px;
    overflow-y: scroll;
}
