.loginContainer{
    min-width: 400px;
    width: auto;
    min-height: 60vh;
    height: fit-content;
    margin-left: 35vw;
    margin-right: 35vw;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 5px 5px 5px 5px;
}

.loginContainer div{
    margin-bottom: 5px;
}

.loginPanel{
    padding: 20px 20px 20px 20px;
    background-color: white;
    width: 80%;
    min-height: 80%;
    height: fit-contentS;
    box-shadow: 5px 5px 5px 5px rgb(0, 0, 0);
}



