.App{
    width: 100vw;
    height: 100vh;
    position: absolute;
}
.center{
    width: 100vw;
    height: 80vh;
    position: absolute;
    margin-top: 50px;
    hyphens: auto;
    -webkit-hyphens: auto;
}
