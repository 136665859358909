.center-panel-model{
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 2px 2px 5px 2px black;
    border: 1px solid black;
    padding: 0px 20px 20px 20px;
    overflow-y: scroll;

}
.dropdown-toggle{
    cursor: pointer;
    width: max-content;
}
.dropdownSelectionMenue{
    padding: 2px 2px 2px 2px;
    cursor: pointer;
}
.dropdownSelection{
    cursor: pointer;
    margin-top: 5px;
    text-align: center;
}