.resetPropertie, .setPropertie{
    background-color: lightgrey;

}

.resetPropertie:hover{
    background-color: lightcoral;
}

.setPropertie:hover{
    background-color: rgb(117, 179, 117);
}

.animatedShow{
    transition: height 1.0s ease-in;

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
    
}


@keyframes fadein {
    from { opacity: 0; transform: translate3d(0, -15%, 0)}
    to   { opacity: 1; transform: translate3d(0, 0, 0)}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}