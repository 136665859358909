.textBackground {
    background-color: white;
    padding: 20px 20px 20px 20px;
    border: 1px black solid;

}

.textBackground p{
    padding: 20px 20px 0px 25px
}

